<template>
  <div v-if="battleDetail" class="view-battle-room">
    <CompHeader title="对战房间" :backFunc="goBack">
      <div class="comp-header-right-box">
        <div class="coin"></div>
      </div>
    </CompHeader>
    <div class="battle-room-header flex-b">
      <div class="left">房间ID：{{ battleDetail.id }}</div>
      <div class="right flex-s">
        <div class="box-num">
          <CompCoin imgName="box-num-bg" :num="boxNum" />
        </div>
        <div class="box-coin">
          <CompCoin :num="boxTotalPrice" />
        </div>
      </div>
    </div>
    <div class="battle-room-choose-box-list">
      <div
        id="scroll-box-8864"
        @mousewheel.stop.prevent="scrollBoxListWheel"
        class="dasx-scroll-box scroll-box flex-s"
        :class="{
          'scroll-box-letter': finalBattleDetail.game_arena_box.length < 6,
        }">
        <div
          @click.stop="showBoxInfoOnClick(item)"
          v-for="(item, index) in battleDetail.game_arena_box"
          :key="item.id"
          class="item"
          :class="{
            'item-active': isShowAnimation && index === currentBoxRound,
            'item-open': currentBoxRound > index,
          }">
          <div class="bg"></div>
          <div class="img-box flex-c">
            <img class="h100" :src="item.box.intact_cover" alt="" srcset="" />
          </div>
          <div class="coin">
            <CompCoin :num="item.box.game_bean" />
          </div>
        </div>
      </div>
    </div>
    <div class="battle-room-scroll-ing">
      <div class="float-title">
        <div
          v-if="finalBattleDetail.type === 1"
          class="item float-title-ouhuang"></div>
        <div v-else class="item float-title-feiqiu"></div>
      </div>
      <div v-if="pageStatus === 0" class="s-main-box flex-a">
        <div
          v-for="(item, index) in finalBattleDetail?.newSeatList"
          :key="index"
          :style="`width:${widthPercentage}`"
          class="main-box-item-status0 flex-c">
          <div v-if="item" style="color: #000" class="user-info-box">
            <div class="seat-ready"></div>
          </div>
          <div
            v-else
            @click="joinRoomOnClick(index)"
            style="color: #000"
            class="join-icon flex-c">
            加入
          </div>
        </div>
      </div>
      <div v-if="finalBattleDetail.status === 2" class="s-main-box">
        <div
          v-show="isShowScrollBox"
          v-if="pageStatus === 2"
          class="scroll-box flex-a">
          <div
            v-for="(item, index) in finalBattleDetail?.newSeatList"
            :key="index"
            :style="`width:${widthPercentage}`"
            class="main-box-item-status2">
            <div
              class="scroll-area"
              :class="{ 'scroll-area-transition': !isRemoveAnimation }"
              :style="`transform: translateY(-${
                singleImgHeight * scrollBoxIndex
              }rem);marginTop:0.16rem;`"
              ref="scrollArea">
              <div
                :style="`height:${singleImgHeight}rem`"
                v-for="(vItem, vIndex) in scrollBoxList[index] || []"
                :key="vIndex"
                class="in-item-box flex-c"
                :class="`battle-bg-lv-${
                  vItem?.level || vItem?.skins?.level || 1
                }`">
                <img
                  class="in-item-box-img"
                  :class="{
                    'in-item-box-img-3': finalBattleDetail.user_num > 2,
                  }"
                  :src="vItem?.skins?.cover || vItem?.box?.intact_cover"
                  alt=""
                  srcset="" />
                {{ vIndex?.skins?.level }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="pageStatus === 3" class="show-bean-box h100 flex-a">
          <div
            v-for="(item, index) in finalBattleDetail?.newSeatList"
            :key="index"
            :style="`width:${widthPercentage}`"
            class="show-bean-box-status2 flex-c"
            :class="{
              'show-bean-box-status2-fail':
                !finalBattleDetail.win_user_id?.includes(item.user_id),
            }">
            <div
              v-if="finalBattleDetail?.win_user?.length"
              class="item-bean flex-c">
              <CompCoin
                imgName="coin-z"
                :num="finalBattleDetail?.win_user[index].win_worth" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="battle-room-reward-box-list">
      <div class="main-box-1">
        <div
          v-for="(item, index) in finalBattleDetail?.newSeatList"
          :key="index"
          :style="`width:${widthPercentage}`"
          class="main-box-item">
          <div class="battle-header-img-wrap flex-c" style="width: 100%">
            <div
              v-if="pageStatus === 3"
              class="win-status-bg"
              :class="{
                'lose-status-bg': !finalBattleDetail.win_user_id?.includes(
                  item.user_id
                ),
              }"></div>
            <div class="battle-header-img flex-c">
              <div class="float-head-img">
                <img
                  class="wh100"
                  :src="
                    item?.user?.avatar ||
                    require(`../../assets/newImages/head-img-null.png`)
                  "
                  alt="" />
              </div>
            </div>
          </div>

          <div class="user-name">
            {{ item?.user?.name || "虚位以待" }}
          </div>
          <div
            v-if="item?.totalPrice && battleDetail.status !== 0"
            class="user-end-coin flex-c">
            <CompCoin imgName="coin-z" tyleList="[0.08, 0.08, 0.1, 0]">
              <CountTo
                :endVal="item?.totalPrice * 1"
                :duration="500"
                :decimals="2"></CountTo>
            </CompCoin>
          </div>
          <div class="scroll-end-box-list-wrap">
            <div
              class="scroll-end-box-list"
              :class="{ 'scroll-end-box-list-pc': !isMobile }">
              <div
                v-for="(vItem, vIndex) in item?.game_award"
                :key="vItem.id"
                :style="`order:${20 - vIndex}`"
                class="box-item"
                :class="{
                  'box-item-hidden': currentBoxRound > vIndex,
                }">
                <div class="open-box-round">第{{ vIndex + 1 }}回合</div>
                <div
                  class="box-info"
                  :class="`battle-bg-lv-${vItem?.skins?.level || 1}`">
                  <div class="text" style="height: 1em">
                    {{
                      vItem?.skins?.dura_alias === "无"
                        ? ""
                        : vItem?.skins?.dura_alias
                    }}
                  </div>
                  <div
                    style="height: 0.4rem; margin-bottom: 0.04rem"
                    class="flex-c">
                    <img
                      class="h-100"
                      :src="vItem?.skins?.cover"
                      alt=""
                      srcset="" />
                  </div>
                  <div style="padding: 0 0.08rem" class="name">
                    <div class="ellipsis">{{ vItem?.skins?.name }}</div>
                  </div>
                  <div class="coin">
                    <CompCoin
                      imgName="coin-z"
                      :styleList="[0.08, 0.08, 0.1, 0.02]"
                      :num="vItem?.skins?.bean" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-overlay
      :lock-scroll="false"
      :show="isNeedShowResult && isShowResult && !!finalDialogResult?.length">
      <div class="overlay-win-box">
        <div
          class="float-header-bg"
          :class="{ 'float-header-bg-lose': isLose }"></div>
        <div class="header-bg flex-b">
          <div class="left">奖励数：{{ finalDialogResult.length }}</div>
          <div class="right flex-s">
            总价值:
            <CompCoin imgName="coin-z" :num="finalDialogPrice" />
          </div>
        </div>
        <div class="main-show-box">
          <div class="scroll-box flex-c">
            <div
              @click="chooseCashBoxOnClick(item)"
              v-for="item in finalDialogResult"
              :key="item.id"
              class="item"
              :class="`battle-bg-lv-${item?.lv || 4}`">
              <div
                v-if="choosedCashIdList.includes(item.id)"
                class="float-border"></div>
              <div class="type-name" style="height: 1em">
                {{ item?.dura_alias === "无" ? "" : item?.dura_alias }}
              </div>
              <div class="img flex-c">
                <img
                  v-if="item?.cover"
                  class="h100"
                  :src="item?.cover"
                  alt=""
                  srcset="" />
                <div v-else class="float-img"></div>
              </div>
              <div class="name ellipsis">
                {{ item?.name || item?.box_name }}
              </div>
              <div class="bean">
                <CompCoin
                  imgName="coin-z"
                  :styleList="[0.1, 0.1, 0.1, 0.02]"
                  :num="item?.bean" />
              </div>
            </div>
          </div>
        </div>
        <div class="btn-box flex-b">
          <div @click="cashOnClick" class="recycle-btn flex-c">
            <div>
              <CompCoin imgName="coin-z" :num="finalCashPrice" />
            </div>
            <div>回收</div>
          </div>
          <div @click="bagOnClick" class="cancel-btn flex-c">放入背包</div>
        </div>
      </div>
    </van-overlay>
    <van-overlay :lock-scroll="false" :show="isShowReady">
      <div
        :style="`left:${readyBgLeft}rem`"
        class="overlay-ready-bg"
        :class="{ 'overlay-ready-bg2': isShowReady2 }"></div>
    </van-overlay>
    <van-overlay :lock-scroll="false" :show="isShowBoxOdds">
      <div v-if="showItem" class="overlay-goods-detail">
        <div
          @click.stop="isShowBoxOdds = false"
          class="float-close-btn point"></div>
        <div class="header-title flex-c">
          {{ showItem.box.name }}
        </div>
        <div class="center-img-box flex-c">
          <img :src="showItem.box.intact_cover" alt="" />
        </div>
        <div class="main-box">
          <div class="odd-list-box flex-c">
            <div
              v-show="item.odds"
              v-for="item in retOddList"
              :key="item.id"
              class="odd-item flex-b">
              <div class="odd-item-in" :class="`odd-item-${item.level}`"></div>
              <div style="width: 0.16rem"></div>
              <div>{{ item.odds.toFixed(4) }}%</div>
            </div>
          </div>
          <div class="goods-list-box">
            <div class="header-bg flex-c">全部饰品</div>
            <div class="goods-list">
              <div
                v-for="vItem in showItem.box.contains"
                :key="vItem.id"
                class="goods-item"
                :class="`goods-item-${vItem.level}`">
                <div class="goods-item-top flex-b">
                  <div class="foo" style="height: 1em">
                    {{
                      vItem?.skins?.dura_alias === "无"
                        ? ""
                        : vItem?.skins?.dura_alias
                    }}
                  </div>
                  <div class="odd">{{ vItem?.odds_percent }}</div>
                </div>
                <div class="goods-item-img flex-c">
                  <img :src="vItem.skins.cover" alt="" />
                </div>
                <div class="goods-item-name flex-c">
                  <div class="name ellipsis">
                    {{ vItem.skins.name }}
                  </div>
                </div>
                <div class="goods-item-bean flex-c">
                  <CompCoin
                    :styleList="[0.1, 0.1, 0.1, 0.02]"
                    :num="vItem.skins.bean" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { getBattleDetailApi, joinBattleRoomApi, Cash } from "@/network/api.js";
import CountTo from "vue-count-to";
export default {
  components: {
    CountTo,
  },
  name: "battleRoom",
  data() {
    return {
      battleDetail: null,
      singleImgHeight: 0.76,
      scrollBoxIndex: 10,
      scrollEndBoxIndex: 20,
      currentBoxRound: 0,
      startCounter: 0,
      transitionTime: 2000,
      parseTime: 1000,
      isRemoveAnimation: false,
      isShowResult: false,
      isNeedShowResult: false,
      isShowReady: false,
      isShowReady2: false,
      readyBgLeft: 3.75,
      isShowAnimation: false,
      showItem: null,
      isShowBoxOdds: false,
      choosedCashIdList: [],
      isShowScrollBox: false,
      Timer: null,
      isJieshu: false,
    };
  },
  watch: {
    JiaRuVsData(val) {
      setTimeout(() => {
        if (!this.isNeedShowResult && !this.isShowAnimation) {
          this.getBattleDetailFun();
        }
      }, 0);
    },
    KaiShiVsData(val) {},
    JieShuVsData(val) {
      const join_user = val.game_arena_player.map((item) => {
        return item.user_id;
      });
      if (join_user.includes(this.user.id)) {
        this.isNeedShowResult = true;
      }
      if (val.id === this.battleDetail.id) {
        this.isJieshu = true;
        console.log("结束请求详情", Date.now());
        this.battleDetail = val;
        if (this.isRoomFull && !this.isShowAnimation) {
          this.isShowAnimation = true;
          this.playReadyAnimation();
          setTimeout(() => {
            this.executeWithInterval(
              this.startPlayFun,
              this.transitionTime + 2000,
              this.battleDetail.game_arena_box.length
            );
          }, 2500);
        }
      }
    },
    RoundVsData(val) {},
  },
  computed: {
    ...mapState([
      "mp3Start",
      "mp3End",
      "isMobile",
      "user",
      "JiaRuVsData",
      "KaiShiVsData",
      "JieShuVsData",
      "RoundVsData",
    ]),
    retOddList() {
      let array = [
        { odds: 0, level: 1 },
        { odds: 0, level: 2 },
        { odds: 0, level: 3 },
        { odds: 0, level: 4 },
      ];
      array.forEach((item, index) => {
        (this?.showItem?.box?.contains || []).forEach((vItem) => {
          if (item.level === vItem.level) {
            item.odds += parseFloat(vItem.odds_percent.replace("%", ""));
          }
        });
      });
      return array;
    },
    widthPercentage() {
      const widthNum = 80 / this.battleDetail.user_num;

      return `${widthNum || 100}%`;
    },
    webTime() {
      return this?.battleDetail?.time * 1000;
    },
    aRoundTime() {
      return this.transitionTime + this.parseTime;
    },
    isInRound() {
      let flag = false;
      const { battleDetail } = this;
      if (!battleDetail) return false;
      const boxLength = battleDetail.game_arena_box.length;
      const TimeDiff = this.webTime - Date.parse(battleDetail.updated_at);
      console.log("时间差", TimeDiff);
      let inRound = Math.floor(TimeDiff / this.aRoundTime) - 2;
      if (inRound < 1) {
        inRound = 0;
      }
      if (inRound <= boxLength) {
        this.startCounter = inRound;
      } else {
        this.startCounter = 9999;
      }
      if (
        battleDetail?.status === 2 &&
        TimeDiff < this.aRoundTime * boxLength * 10000
      ) {
        flag = true;
      }
      return flag;
    },
    pageStatus() {
      let status = 0;
      if (this.battleDetail?.status === 0) {
        status = 0;
      } else if (this.battleDetail?.status === 1) {
        status = 1;
      } else if (this.battleDetail?.status === 2 && this.isShowAnimation) {
        status = 2;
      } else if (this.battleDetail?.status === 2 && !this.isShowAnimation) {
        status = 3;
      }
      return status;
    },
    routeId() {
      return this.$route.query.id;
    },
    isRoomFull() {
      let flag = true;
      if (
        this.battleDetail.user_num !==
        this.battleDetail.game_arena_player.length
      ) {
        flag = false;
      }
      return flag;
    },
    boxNum() {
      return this.battleDetail?.game_arena_box?.length || 0;
    },
    boxTotalPrice() {
      return (
        this.battleDetail?.game_arena_box?.reduce(
          (total, item) => total + item.box.game_bean * 1,
          0
        ) || 0
      );
    },
    finalBattleDetail() {
      const { battleDetail } = this;
      let newSeatList = new Array(battleDetail.user_num).fill(null);
      newSeatList = newSeatList.map((wItem, index) => {
        let seatItem = wItem;
        battleDetail.game_arena_player.forEach((vItem) => {
          if (vItem.seat === index) {
            seatItem = vItem;
            seatItem.totalPrice = 0;
            seatItem.game_award.forEach((wItem, wIndex) => {
              if (this.currentBoxRound > wIndex) {
                seatItem.totalPrice += wItem?.skins?.bean * 1;
              }
            });
            seatItem.totalPrice = seatItem.totalPrice.toFixed(2);
          }
        });
        return seatItem;
      });
      return {
        ...this.battleDetail,
        newSeatList,
      };
    },
    scrollBoxList() {
      if (this.currentBoxRound < this.battleDetail.game_arena_box.length) {
        const userArray = new Array(this.battleDetail.user_num).fill(null);
        const boxList =
          this.battleDetail.game_arena_box[this.currentBoxRound]?.box?.contains;
        userArray.forEach((item, index) => {
          const newScrollBoxList = new Array(40).fill(null);
          const resArray = [];
          newScrollBoxList.forEach((vItem, vIndex) => {
            const randomItem =
              boxList[Math.floor(Math.random() * boxList.length)];
            resArray.push(randomItem);
            if (vIndex === this.scrollEndBoxIndex) {
              resArray[vIndex] =
                this.finalBattleDetail.newSeatList[index].game_award[
                  this.currentBoxRound
                ];
            }
          });

          userArray[index] = resArray;
        });
        return userArray;
      } else {
        return [];
      }
    },
    isWin() {
      let flag = false;
      if (
        (this.battleDetail.win_user_id || []).includes(this.user.id) &&
        this.battleDetail.win_user_id?.length === 1
      ) {
        flag = true;
      }
      return flag;
    },
    isLose() {
      let flag = false;
      if (!(this.battleDetail.win_user_id || []).includes(this.user.id)) {
        flag = true;
      }
      return flag;
    },
    finalDialogResult() {
      console.log("弹窗数据之前", this.isRoomFull, this.isNeedShowResult);
      if (this.isRoomFull && this.isNeedShowResult) {
        let retList = [];
        if (this.isLose) {
          if (this.finalBattleDetail?.loser_user_data) {
            retList = this.finalBattleDetail?.loser_user_data[this.user.id];
          }
        } else {
          if (this.finalBattleDetail?.win_user_data) {
            retList = this.finalBattleDetail?.win_user_data[this.user.id];
          }
        }
        console.log("弹窗数据", retList);
        return retList;
      } else {
        return [];
      }
    },
    finalDialogPrice() {
      let totalPrice = 0;
      this.finalDialogResult.forEach((item) => {
        totalPrice += item.bean * 1;
      });
      return totalPrice.toFixed(2);
    },
    finalCashPrice() {
      let totalPrice = 0;
      this.finalDialogResult.forEach((item) => {
        if (this.choosedCashIdList.includes(item.id)) {
          totalPrice += item.bean * 1;
        }
      });
      return totalPrice.toFixed(2);
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions(["Refresh"]),
    async init() {
      if (!this.isNeedShowResult) {
        this.getBattleDetailFun();
      }
    },
    scrollBoxListWheel(e) {
      console.log(3333, e);
      const dom = document.querySelectorAll(".dasx-scroll-box")[0];
      let left = dom.scrollLeft;
      if (e.deltaY > 0) {
        left += 10;
      } else {
        left -= 10;
      }
      dom.scrollLeft = left;
    },
    goBack() {
      this.$router.push("/dustBatter");
    },
    chooseCashBoxOnClick(item) {
      const index = this.choosedCashIdList.indexOf(item.id);
      if (index > -1) {
        this.choosedCashIdList.splice(index, 1);
      } else {
        this.choosedCashIdList.push(item.id);
      }
    },
    bagOnClick() {
      this.isNeedShowResult = false;
      this.isShowResult = false;
      this.isJieshu = false;
      this.getBattleDetailFun();
    },
    cashOnClick() {
      if (!this.choosedCashIdList.length) {
        this.$message.error("请选择要回收的物品");
        return false;
      }
      Cash(this.choosedCashIdList).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "回收成功",
            type: "success",
          });
          this.Refresh();
          this.isNeedShowResult = false;
          this.isShowResult = false;
          this.isJieshu = false;
          this.getBattleDetailFun();
        } else {
          this.$message({
            message: res.data.message,
            type: "error",
          });
        }
      });
    },
    showBoxInfoOnClick(item) {
      this.showItem = item;
      this.isShowBoxOdds = true;
    },
    playReadyAnimation() {
      this.isShowReady = true;
      setTimeout(() => {
        this.readyBgLeft = 0;
        setTimeout(() => {
          this.isShowReady2 = true;
        }, 1100);
        setTimeout(() => {
          this.readyBgLeft = -3.75;
          this.isShowReady = false;
        }, 2000);
      }, 0);
    },
    executeWithInterval(callback, interval, times = 1) {
      this.isShowScrollBox = true;
      callback();
      if (times - this.currentBoxRound <= 0) {
        setTimeout(() => {
          this.isShowAnimation = false;
          this.isShowResult = true;
          this.finalDialogResult.forEach((item) => {
            this.choosedCashIdList.push(item.id);
          });
          this.isShowScrollBox = false;
        }, this.aRoundTime);
      }
      if (times - this.currentBoxRound > 0) {
        const intervalId = setInterval(() => {
          this.isShowScrollBox = true;
          callback();
          if (this.currentBoxRound >= times) {
            this.isShowAnimation = false;
            this.isShowResult = true;
            this.finalDialogResult.forEach((item) => {
              this.choosedCashIdList.push(item.id);
            });
            clearInterval(intervalId);
          }
        }, interval);
      }
    },
    startPlayFun() {
      //   this.mp3Start.currentTime = 0;
      //   this.mp3Start.play();
      this.isRemoveAnimation = true;
      this.scrollBoxIndex = 0;
      const maxRound = this.battleDetail.game_arena_box.length;
      setTimeout(() => {
        if (this.currentBoxRound < maxRound) {
          this.currentBoxRound += 1;
          this.isShowScrollBox = false;
        }
        const scrollDom = document.getElementById("scroll-box-8864");
        if (scrollDom) {
          const singleWidth = scrollDom?.scrollWidth / maxRound;
          scrollDom.scrollTo({
            left: singleWidth * (this.currentBoxRound - 2),
            behavior: "smooth",
          });
        }
        // this.mp3Start.pause();
        // this.mp3End.play();
      }, this.aRoundTime);
      setTimeout(() => {
        this.isRemoveAnimation = false;
        this.scrollBoxIndex = this.scrollEndBoxIndex;
      }, 50);
    },
    async joinRoomOnClick(index) {
      try {
        const params = {
          game_arena_id: this.routeId,
          seat: index,
          type: 2,
        };
        const res = await joinBattleRoomApi(params);
        if (res?.data.code === 200) {
          this.Refresh();
        } else {
          this.$message.error(res?.data?.message || "系统异常，请刷新后重试");
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
    async getBattleDetailFun() {
      if (this.isJieshu) {
        return false;
      }
      try {
        const params = {
          game_arena_id: this.routeId,
        };
        const res = await getBattleDetailApi(params);
        if (res?.data?.code === 200) {
          console.log("加入请求详情", Date.now(), this.isNeedShowResult);
          if (!this.isNeedShowResult) {
            this.battleDetail = res.data.data;
            if (this.isRoomFull) {
              if (!this.isInRound && this.battleDetail.status === 2) {
                this.currentBoxRound = 9999;
              }
              if (this.isInRound && !this.isShowAnimation) {
                this.isShowAnimation = true;
                this.currentBoxRound = this.startCounter;
                if (
                  this.currentBoxRound < this.battleDetail.game_arena_box.length
                ) {
                  this.executeWithInterval(
                    this.startPlayFun,
                    this.transitionTime + 2000,
                    this.battleDetail.game_arena_box.length
                  );
                } else {
                  this.isShowAnimation = false;
                }
              }
            }
          }
        } else {
          this.$message.error("获取对战详情信息失败");
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
};
</script>

<style lang="scss">
.view-battle-room {
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0.58rem 0rem 0rem;
  position: relative;
  .overlay-goods-detail {
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    top: 9vh;
    height: 91vh;
    left: 0;
    width: 100%;
    border-top: 0.02rem solid rgba(255, 215, 29, 1);
    padding: 0.18rem 0.2rem 0;
    .float-close-btn {
      position: absolute;
      width: 0.2rem;
      height: 0.2rem;
      right: 0.12rem;
      top: 0.12rem;
      @include bgImg("close-btn");
    }
    .header-title {
      font-weight: 500;
      font-size: 0.16rem;
      margin-bottom: 0.12rem;
    }
    .center-img-box {
      margin-bottom: 0.2rem;
      img {
        width: 1.58rem;
      }
    }
    .main-box {
      .odd-list-box {
        margin-bottom: 0.12rem;
        .odd-item {
          margin: 0 0.06rem;
          padding: 0rem 0rem;
          position: relative;
          font-size: 0.1rem;
          .odd-item-in {
            position: absolute;
            left: 0;
            top: 0;
            width: 0.28rem;
            height: 100%;
          }
          .odd-item-1 {
            @include bgImg("odd0");
          }
          .odd-item-2 {
            @include bgImg("odd1");
          }
          .odd-item-3 {
            @include bgImg("odd2");
          }
          .odd-item-4 {
            @include bgImg("odd3");
          }
        }
      }
      .goods-list-box {
        width: 100%;
        min-height: 1rem;
        background: #1d1300;
        border: 0.01rem solid #69552d;
        .header-bg {
          width: 100%;
          height: 0.36rem;
          font-weight: 500;
          font-size: 12px;
          margin-bottom: 0.1rem;
          @include bgImg("battle-dialog-bg");
        }
        .goods-list {
          width: 100%;
          padding: 0 0.1rem;
          max-height: 2rem;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 0.07rem;
          overflow-y: auto;
          .goods-item {
            width: 0.99rem;
            height: 0.8rem;
            margin-bottom: 0.08rem;
            font-size: 0.1rem;
            padding: 0.03rem;
            .goods-item-top {
            }
            .goods-item-img {
              margin-top: -0.04rem;
              img {
                height: 0.48rem;
              }
            }
            .goods-item-name {
              padding: 0 0.08rem;
              margin-top: -0.04rem;
            }
            .goods-item-bean {
            }
          }
          .goods-item-1 {
            @include bgImg("openbox-bg0");
          }
          .goods-item-2 {
            @include bgImg("openbox-bg1");
          }
          .goods-item-3 {
            @include bgImg("openbox-bg2");
          }
          .goods-item-4 {
            @include bgImg("openbox-bg3");
          }
        }
      }
    }
  }
  // 胜利弹窗
  .overlay-win-box {
    position: absolute;
    width: 3.35rem;
    min-height: 1rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #1d1300;
    border: 0.01rem solid #69552d;
    .float-header-bg {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -0.62rem;
      width: 2rem;
      height: 1rem;
      @include bgImg("battle-win-header-bg");
    }
    .float-header-bg-lose {
      @include bgImg("battle-lose-header-bg");
    }
    .header-bg {
      width: 100%;
      height: 0.36rem;
      padding: 0 0.12rem;
      font-size: 0.12rem;
      @include bgImg("battle-dialog-bg");
    }
    .main-show-box {
      padding: 0.2rem 0.12rem;
      .scroll-box {
        width: 100%;
        max-height: 1.63rem;
        flex-wrap: wrap;
        overflow-y: scroll;
        .item {
          width: 0.7rem;
          height: 0.76rem;
          //   background: skyblue;
          margin-bottom: 0.11rem;
          margin: 0 0.03rem;
          margin-bottom: 0.11rem;
          position: relative;
          font-size: 0.1rem;
          text-align: center;
          padding: 0.02rem 0.02rem;
          .float-border {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            @include bgImg("battle-item-border-bg");
          }
          .type-name {
          }
          .img {
            width: 100%;
            height: 0.44rem;
            margin-top: -0.07rem;
            position: relative;
            .float-img {
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              @include bgImg("money-bag");
            }
          }
          .name {
          }
          .bean {
          }
        }
      }
    }
    .btn-box {
      padding: 0rem 0.12rem 0.2rem 0.12rem;
      font-weight: 500;
      font-size: 0.14rem;
      color: #ffffff;
      .recycle-btn {
        width: 1.5rem;
        height: 0.3rem;
        @include bgImg("guanzhan-bg");
      }
      .cancel-btn {
        width: 1.5rem;
        height: 0.3rem;
        @include bgImg("battle-cancel-bg");
      }
    }
  }
  .overlay-ready-bg {
    position: absolute;
    width: 100%;
    height: 2.02rem;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.5s;
    @include bgImg("ready-start-bg");
  }
  .overlay-ready-bg2 {
    @include bgImg("ready-start-bg2");
  }
  // 对战出货背景
  .battle-bg-lv-1 {
    @include bgImg("battle-award-bg-lv1");
  }
  .battle-bg-lv-2 {
    @include bgImg("battle-award-bg-lv2");
  }
  .battle-bg-lv-3 {
    @include bgImg("battle-award-bg-lv3");
  }
  .battle-bg-lv-4 {
    @include bgImg("battle-award-bg-lv4");
  }
  .battle-room-header {
    padding: 0.08rem 0.2rem;
    font-size: 0.12rem;
    .left {
    }
    .right {
      .box-num {
        margin-right: 0.12rem;
        padding: 0.01rem 0.13rem;
        border: 0.01rem solid rgba(129, 112, 67, 0.4);
      }
      .box-coin {
        padding: 0.01rem 0.13rem;
        border: 0.01rem solid rgba(129, 112, 67, 0.4);
      }
    }
  }
  .battle-room-choose-box-list {
    width: 100%;
    // height: 0.72rem;
    margin-bottom: 0.2rem;
    padding: 0.08rem 0.15rem;
    background: linear-gradient(180deg, #1d1300 0%, rgba(29, 19, 0, 0) 100%);
    .scroll-box {
      width: 100%;
      //   height: 100%;
      overflow-x: auto;
      transition: all 0.5s;

      .item {
        width: 0.72rem;
        height: 100%;
        padding: 0.08rem 0.08rem;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        position: relative;
        .img-box {
          //   width: 0.44rem;
          height: 0.44rem;
          margin-bottom: 0.03rem;
        }
        .coin {
        }
      }
      .item-open {
        filter: grayscale(1);
      }
      .item-active {
        .bg {
          position: absolute;
          left: 0;
          top: 0;
          width: 105%;
          height: 105%;
          @include bgImg("box-item-active-bg");
        }
      }
    }
    .scroll-box-letter {
      justify-content: center;
    }
  }
  .battle-room-scroll-ing {
    width: 100%;
    height: 1.26rem;
    flex-shrink: 0;
    // background: #eee;
    position: relative;
    padding: 0.09rem 0rem;
    z-index: 1;
    .s-main-box {
      width: 100%;
      height: 100%;
      overflow: hidden;
      .main-box-item-status0 {
        width: 20%;
        height: 100%;
        overflow-y: auto;
        .seat-ready {
          width: 0.32rem;
          height: 0.32rem;
          @include bgImg("seat-ready-icon");
        }
        .join-icon {
          width: 0.64rem;
          height: 0.22rem;
          @include bgImg("seat-not-ready-icon");
          font-weight: 500;
          font-size: 0.12rem;
          color: #151413;
        }
      }
      .main-box-item-status2 {
        // width: 0.7rem;
        height: 100%;
        // overflow-y: auto;
        width: 20%;
        padding: 0 0rem;
        .scroll-area {
          margin-top: 0rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .in-item-box {
            // width: 1rem;
            // border: 1px solid red;
            .in-item-box-img {
              width: 1rem;
            }
            .in-item-box-img-3 {
              width: 0.7rem;
            }
          }
          // .in-item-box-3{
          //   width: 0.7rem;
          // }
        }
        .scroll-area-transition {
          transition: transform 2s cubic-bezier(0.25, 0.1, 0.25, 1);
        }
      }
      .show-bean-box-status2 {
        height: 100%;
        width: 20%;
        padding: 0 0rem;
        .item-bean {
          width: 100%;
          height: 0.64rem;
          @include bgImg("bg38804");
        }
      }
      .show-bean-box-status2-fail {
        .item-bean {
          @include bgImg("bg38805");
        }
      }
    }
    .float-title {
      width: 0.98rem;
      height: 0.18rem;
      position: absolute;
      left: 50%;
      top: 0;
      z-index: 1;
      transform: translate(-50%, -50%);
      .item {
        width: 100%;
        height: 100%;
      }
      .float-title-ouhuang {
        @include bgImg("ouhuang-title-bg");
      }
      .float-title-feiqiu {
        @include bgImg("feiqiu-title-bg");
      }
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      //   background: skyblue;
      @include bgImg("battle-bg1");
    }
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -2;
      //   background: skyblue;
      @include bgImg("battle-bg2");
    }
  }
  .battle-room-reward-box-list {
    padding: 0.16rem 0rem 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    .main-box-1 {
      flex-grow: 1;
      display: flex;
      justify-content: space-around;
      align-items: start;

      .main-box-item {
        overflow: hidden;
        height: 100%;
        background: rgba(29, 19, 0, 0.6);
        border: 0.01rem solid #372e1d;
        border-top: 0.015rem solid rgba(255, 215, 29, 0.8);
        border-bottom: 0;
        width: 23%;
        padding: 0.03rem 0rem;
        font-weight: 500;
        font-size: 0.1rem;
        color: #ffffff;
        background: linear-gradient(
          180deg,
          rgba(255, 215, 29, 0.3) 0%,
          rgba(255, 215, 29, 0) 14%
        );
        // min-height: 51.4vh;
        .battle-header-img-wrap {
          position: relative;
          .win-status-bg {
            position: absolute;
            left: 50%;
            top: -0.12rem;
            transform: translateX(-50%);
            width: 1rem;
            height: 0.44rem;
            @include bgImg("bg38810");
          }
          .lose-status-bg {
            @include bgImg("bg38811");
          }
        }
        .battle-header-img {
          width: 0.58rem;
          height: 0.58rem;
          margin-bottom: 0.02rem;
          @include bgImg("head-img-bg2");
          .float-head-img {
            width: 0.38rem;
            height: 0.38rem;
            border-radius: 50%;
            overflow: hidden;
            margin-top: -0.02rem;
          }
        }
        .user-name {
          text-align: center;
          margin-bottom: 0.06rem;
        }
        .user-end-coin {
          margin-bottom: 0.1rem;
        }
        .scroll-end-box-list-wrap {
          width: 100%;
          flex-grow: 1;
          display: flex;
          justify-content: center;
          max-height: calc(100vh - 4.3rem);
          overflow-y: auto;
        }
        .scroll-end-box-list {
          width: 0.7rem;
          //   overflow-y: auto;
          height: 100%;
          //   height: 2.7rem;
          //   height: calc(100vh - 4.3rem);
          //   max-height: 50vh;
          display: flex;
          flex-direction: column;
          .box-item {
            width: 100%;
            margin-bottom: 0rem;
            height: 0;
            flex-shrink: 0;
            overflow: hidden;
            transition: height 0.5s ease-in-out; /* 过渡效果 */
            .open-box-round {
              width: 100%;
              font-size: 0.1rem;
              color: #b17a43;
              margin-bottom: 0.04rem;
              text-align: center;
            }
            .box-info {
              width: 100%;
              padding-bottom: 0.05rem;
              .text {
                font-size: 0.1rem;
                text-align: center;
              }
            }
          }
          .box-item-hidden {
            margin-bottom: 0.1rem;
            height: 1rem;
            width: 100%;
            padding: 0 0.06rem;
          }
        }
        .scroll-end-box-list-pc {
          height: calc(100vh - 4.31rem);
        }
      }
    }
  }
}
</style>
